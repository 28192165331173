import "core-js/modules/es.array.push.js";
import { baseActivePage } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        server_active_name: '',
        apply_state: 1,
        active_type: '',
        set_type: '',
        state: 1,
        current: 1,
        total: 0
      },
      exportIds: '',
      checkMuneSer: [],
      fileUrl: '../../static/file/course.xlsx'
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.server_active_name = '';
      this.pageInfo.active_type = '';
      this.pageInfo.set_type = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      baseActivePage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    showEdit(id) {
      this.$root.useRouter.push({
        path: "/base/order/orderList",
        query: {
          id: id
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};